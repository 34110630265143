import { useState } from "react";
import { isSameSubnet } from "../../../settings/venue/device/NetworkConfig/networkUtils";
import { addVirtualInterface, getDefaultSubnetIp, getVirtualInterfaceIpFromProcessorIp, hasIpAddressesOnSameSubnet } from "../hub/hubUtils";
import EditableForm from "../../table/EditableForm";
import ProcessorScan from "./ProcessorScan";
import Button from "../../../UiComponents/Button";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import { ModalHeader } from "../../../panel/ModalStyles";

const Frame = styled.div`
    box-sizing: border-box;
    border: 3px solid ${theme.colors.darkSpace80};
    padding: 15px 30px ;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap 20px;
`

const setupStates = {
    SCAN: 0,
    FORM: 1
}

const ProcessorSetup = ({ hubs, columns, processors, onSubmit, cancel, preSelectedProcessor, fixedSetupState }) => {
    const [setupState, setSetupState] = useState(fixedSetupState==='SCAN' ? setupStates.SCAN : setupStates.FORM);
    const [selectedProcessor, setSelectedProcessor] = useState(preSelectedProcessor || {});

    const selectProcessor = async (processor, subnet) => {
        const hub = hubs.find(hub => hub.hubId === processor.hubId);
        if (!hasIpAddressesOnSameSubnet(processor.ipAddress, hub.networkConfig)) {
            await addVirtualInterfaceHandler(processor, subnet, hub);
        }

        setSelectedProcessor(processor);
        setSetupState(setupStates.FORM);
    }

    const toggleSetupState = () => {
        setSetupState(setupState === setupStates.SCAN ? setupStates.FORM : setupStates.SCAN);
    }

    return (<>
        {!fixedSetupState &&
            <Button
                style={{ width: '200px'}}
                small
                tertiary
                onClick={() => toggleSetupState()}
                >{setupState === setupStates.SCAN ? 'Add manually' : 'Scan for processors'}
            </Button>
        }
        {setupState === setupStates.FORM ?
            <Frame>
                <ModalHeader style={{fontSize: '1.2rem'}}>Processor Setup</ModalHeader>
                <EditableForm
                    initProps={selectedProcessor}
                    columns={columns}
                    objects={processors}
                    onSubmit={onSubmit}
                    onCancel={cancel}
                    close={() => cancel()}
                />
            </Frame>
        :
            <Frame>
                <ProcessorScan
                    hubs={hubs}
                    selectProcessor={selectProcessor}
                    preSelectedProcessor={preSelectedProcessor}
                    fixedSetupState={fixedSetupState}
                />
            </Frame>
        }
    </>)
}

export default ProcessorSetup;

const addVirtualInterfaceHandler = async (processor, subnet, hub) => {
    let virtualIp;
    if (subnet && isSameSubnet(processor.ipAddress, subnet)) {
        virtualIp = getDefaultSubnetIp(subnet);
    } else {
        virtualIp = getVirtualInterfaceIpFromProcessorIp(processor.ipAddress);
    }
    if (virtualIp) {
        await addVirtualInterface(hub, virtualIp);
    }
}