import React, { useState } from 'react';
import Modal from '../../UiComponents/Modal';
import EditableForm from './EditableForm';
import { ListActionButton } from './styles/ActionStyles';
import { FaPlus } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import { ModalContainer } from '../../panel/ModalStyles';
import Button from '../../UiComponents/Button';

const StyledButton = styled(ListActionButton)`
    ${props => props.highlighted && css`
        color: ${theme.colors.darkSpace};
        svg {
            color: ${theme.colors.darkSpace};
        }
        background-color: ${theme.colors.greenEnergy};
    `}
`

const AddObjectButton = ({ columns, onSubmit, objects, disabled, highlighted, title, overrideButtonStyle, overrideModal}) => {
    const [showModal, setShowModal] = useState(false);

    const submitHandler = async (object, submitProps) => {
        await onSubmit(object, submitProps);
        setShowModal(false);
    }

    const cancelHandler = async () => {
        setShowModal(false);
    }

    const getSuggestedValues = (columns) => {
        const suggestedValues = {};
        columns.filter(column => column.form && column.getSuggested).forEach(column => {
            suggestedValues[column.key] = column.getSuggested();
        });
        return suggestedValues;
    }

    return <>

        {overrideButtonStyle ?
            <Button primary small onClick={() => setShowModal(true)}>{overrideButtonStyle.title}</Button>
        :
            <StyledButton disabled={disabled} highlighted={highlighted} secondary small onClick={() => setShowModal(true)}>
                <FaPlus />
                <label>Add</label>
            </StyledButton> 
        }
        {showModal && !overrideModal ? <>
            <Modal show={showModal} close={() => setShowModal(false)}>
                <ModalContainer>
                    <EditableForm
                        columns={columns}
                        objects={objects}
                        onSubmit={submitHandler}
                        onCancel={cancelHandler}
                        title={title}
                    />
                </ModalContainer>
            </Modal>
        </> : <></>}
        {showModal && overrideModal ? <>{overrideModal(cancelHandler, getSuggestedValues(columns))}</> : <></>}
    </>
}

export default AddObjectButton;

